
import { defineComponent, onMounted, ref, reactive, onBeforeUnmount } from 'vue'
import store from '@/store'
import router from '@/router'
import * as echarts from 'echarts'
import Api from '@/api'
type chartType = {
  category: echarts.ECharts
  pie: echarts.ECharts
  bar: echarts.ECharts
}
export default defineComponent({
  setup() {
    onMounted(() => {
      get_http_loginInfo()
      get_http_applyStatistics()
      get_http_applyPass()
      get_http_applyHistogram('init')
      // chartInstance.category = categoryChart()
      // barChart()
      // pieChart()
      get_http_sumUpWorkAdmin('init')
      get_http_backlogResEmpowers()
      get_http_backlogResChanges()
      get_http_backlogPatents(1)
      get_http_backlogPatents(2)
    })
    onBeforeUnmount(() => {
      chartInstance.category.dispose()
      chartInstance.pie.dispose()
    })
    const chartInstance: chartType = {} as chartType

    const card1Data = reactive({
      name: store.state.userInfo.name,
      sexStr: store.state.userInfo.sex === 1 ? '先生' : '女士',
      loginTime: '07/10 15:00',
      registerDay: 200,
      loginDay: 53
    })
    function get_http_loginInfo() {
      Api.http_loginInfo({ role: 4 }).then((res) => {
        if (res.code === '200') {
          const loginDate = new Date(res.data.login_time)
          card1Data.loginTime = `${
            loginDate.getMonth() + 1
          }/${ loginDate.getDate() } ${ loginDate
            .getHours()
            .toString()
            .padStart(2, '0') }:${ loginDate
            .getMinutes()
            .toString()
            .padStart(2, '0') }`
          card1Data.registerDay = res.data.register_length
          card1Data.loginDay = res.data.login_length
        }
      })
    }

    // ------------------------------
    const card2Data = reactive({
      select: 1,
      onSelectChange(e) {
        get_http_applyStatistics()
      },
      year: new Date(),
      onYearChange(e) {
        get_http_applyStatistics()
      },
      quarter: 0, // 季度
      onQuarterChange(e) {
        get_http_applyStatistics()
      },
      quarterCount: 0,
      quarterRatio: 0,
      yearRatio: 0
    })
    function get_http_applyStatistics() {
      Api.http_applyStatistics({
        year: card2Data.year.getFullYear(),
        quarter: card2Data.quarter,
        role: 4,
        type: card2Data.select
      }).then((res) => {
        if (res.code === '200') {
          card2Data.quarterCount = res.data.quarterCount
          card2Data.quarterRatio = Number(
            (res.data.quarterRatio * 100).toFixed(2)
          )
          card2Data.yearRatio = Number((res.data.yearRatio * 100).toFixed(2))
        }
      })
    }

    // ---------------------
    const card3Data = reactive({
      select: 1,
      onSelectChange(e) {
        get_http_applyPass()
      },
      year: new Date(),
      onYearChange(e) {
        get_http_applyPass()
      },
      quarter: 0, // 季度
      onQuarterChange(e) {
        get_http_applyPass()
      },
      passRatio: 0,
      submitCount: 0,
      passCount: 0
    })
    function get_http_applyPass() {
      Api.http_applyPass({
        year: card3Data.year.getFullYear(),
        quarter: card3Data.quarter,
        role: 4,
        type: card3Data.select
      }).then((res) => {
        if (res.code === '200') {
          card3Data.submitCount = res.data.quarterCount // 提交份数
          card3Data.passRatio = Number((res.data.quarterRatio * 100).toFixed(2)) // 页面显示%比
          card3Data.passCount = res.data.quarterPassCount // 通过份数
        }
      })
    }

    // --------------------------
    const card4Data = reactive({
      chart: {
        data: [
          { value: 1048, name: '专利申请前' },
          { value: 1048, name: '成果赋权' },
          { value: 1048, name: '技术转移' },
          { value: 1048, name: '专利水平' }
        ],
        title: {
          text: ''
        }
      },
      year: new Date(),
      onYearChange() {
        get_http_sumUpWorkAdmin()
      }
    })
    function updatedPie() {
      chartInstance.pie.setOption({
        title: {
          text: card4Data.chart.title.text
        },
        series: {
          data: card4Data.chart.data
        }
      })
    }
    function get_http_sumUpWorkAdmin(optType = 'update') {
      Api.http_sumUpWorkAdmin({
        role: 4,
        year: card4Data.year.getFullYear()
      }).then((res) => {
        if (optType === 'init') {
          chartInstance.pie = pieChart([
            res.data.patentsCount,
            res.data.resEmpowersCount,
            res.data.resChangesCount,
            res.data.patentsSuffixCount
          ])
        } else if (optType === 'update') {
          card4Data.chart.data[0].value = res.data.patentsCount
          card4Data.chart.data[1].value = res.data.resEmpowersCount
          card4Data.chart.data[2].value = res.data.resChangesCount
          card4Data.chart.data[3].value = res.data.patentsSuffixCount
          card4Data.chart.title.text
            = res.data.patentsCount
            + res.data.resChangesCount
            + res.data.resEmpowersCount
            + res.data.patentsSuffixCount
          updatedPie()
        }
      })
    }
    const card5Data = reactive({
      chart: {
        xAxis: [
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月'
        ],
        data: [
          820,
          932,
          901,
          934,
          190,
          1330,
          1320,
          820,
          932,
          901,
          934,
          1290,
          1330,
          1320
        ]
      },
      select: 1,
      onSelectChange(e) {
        card5Data.radioBtnSelect = 0
        get_http_applyHistogram()
      },
      util: ['单位:件', '单位:件', '单位:件'],
      radioBtn: [
        ['全部', '转让许可', '作价投资'],
        ['全部', '所有权', '长期使用权'],
        ['提交数', '通过数']
      ],
      radioBtnSelect: 0,
      onRadioBtnSelectChange(e) {
        get_http_applyHistogram()
      },
      // curTab: 'first',
      // onTabToggleBefore(activeName, oldActiveName) { console.log(activeName, oldActiveName) },
      dateType: 1,
      onDateTypeChange(e) {
        if (e === 1) {
          card5Data.chart.xAxis = [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
          ]
          // card5Data.chart.data = [820, 932, 901, 934, 190, 1330, 1320, 820, 932, 901, 934, 1290, 1330, 1320]
        }
        if (e === 2) {
          card5Data.chart.xAxis = ['1季度', '2季度', '3季度', '4季度']
          // card5Data.chart.data = [820, 932, 901, 934]
        }
        get_http_applyHistogram()
        // updatedCategory(card5Data.chart)
      },
      year: new Date(),
      onYearChange(e) {
        get_http_applyHistogram()
      }
    })
    // 获取趋势图
    function get_http_applyHistogram(optType = 'update') {
      let way = card5Data.radioBtnSelect
      if (card5Data.select === 3) way++
      Api.http_applyHistogram({
        type: card5Data.select,
        way: way,
        section: card5Data.dateType,
        year: card5Data.year.getFullYear(),
        role: 4
      }).then((res) => {
        if (res.code === '200') {
          if (optType === 'init') {
            chartInstance.category = categoryChart(res.data)
          } else if (optType === 'update') {
            card5Data.chart.data = res.data
            updatedCategory(card5Data.chart)
          }
        }
      })
    }
    function updatedCategory(chart) {
      // console.log('updatedCategory', chart.data)
      chartInstance.category.setOption({
        xAxis: [
          {
            data: chart.xAxis
          }
        ],
        series: [
          {
            data: chart.data
          }
        ]
      })
      // chartInstance.category.resize()
    }
    const card6Data = reactive({
      curTab: 'first',
      opt: [
        { label: '全部代办事项', len: 0, name: 'first', list: [] as any },
        { label: '专利申请前', len: 0, name: 'second', list: [] as any },
        { label: '专利水平', len: 0, name: 'fifth', list: [] as any },
        { label: '成果转化', len: 0, name: 'third', list: [] as any },
        { label: '成果赋权', len: 0, name: 'fourth', list: []as any }
      ],
      onTabToggleBefore(activeName, oldActiveName) {
        console.log(activeName, oldActiveName)
      }
    })
    function get_http_backlogPatents(patent_type) {
      Api.http_backlogPatents({ offset: 0, length: 3, patent_type }).then(
        (res) => {
          if (res.code === '200') {
            if (patent_type === 1) {
              res.data.data.forEach((element, index) => {
                res.data.data[index].routerName = 'patent-task'
                res.data.data[index].business = '专利申请前评估'
              })
              card6Data.opt[1].len = res.data.total
              card6Data.opt[1].list = res.data.data
              card6Data.opt[0].len += res.data.total
              card6Data.opt[0].list = card6Data.opt[0].list.concat(
                res.data.data
              )
            } else {
              res.data.data.forEach((element, index) => {
                res.data.data[index].routerName = 'patent-task-level'
                res.data.data[index].business = '专利水平评估'
              })
              card6Data.opt[2].len = res.data.total
              card6Data.opt[2].list = res.data.data
              card6Data.opt[0].len += res.data.total
              card6Data.opt[0].list = card6Data.opt[0].list.concat(
                res.data.data
              )
            }
          }
        }
      )
    }
    function get_http_backlogResChanges() {
      Api.http_backlogResChanges({ offset: 0, length: 3 }).then((res) => {
        if (res.code === '200') {
          res.data.data.forEach((element, index) => {
            res.data.data[index].routerName = 'change-audit'
            res.data.data[index].business = '成果转化'
          })
          card6Data.opt[3].len = res.data.total
          card6Data.opt[3].list = res.data.data
          card6Data.opt[0].len += res.data.total
          card6Data.opt[0].list = card6Data.opt[0].list.concat(res.data.data)
        }
      })
    }
    function get_http_backlogResEmpowers() {
      Api.http_backlogResEmpowers({ offset: 0, length: 3 }).then((res) => {
        if (res.code === '200') {
          res.data.data.forEach((element, index) => {
            res.data.data[index].routerName = 'empower-audit'
            res.data.data[index].business = '成果赋权'
          })
          card6Data.opt[4].len = res.data.total
          card6Data.opt[4].list = res.data.data
          card6Data.opt[0].len += res.data.total
          card6Data.opt[0].list = card6Data.opt[0].list.concat(res.data.data)
        }
      })
    }
    function navToAudit(routerName) {
      router.push({ name: routerName })
    }

    return {
      card1Data,
      card2Data,
      card3Data,
      card4Data,
      card5Data,
      card6Data,
      navToAudit
    }
  }
})

function categoryChart(data): echarts.ECharts {
  const chart = echarts.init(
    document.querySelector('#dean-chart1') as HTMLElement
  )
  chart.setOption({
    grid: {
      left: 60,
      right: 30,
      top: 10,
      bottom: 30
    },
    xAxis: {
      type: 'category',
      data: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月'
      ]
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      trigger: 'axis'
    },
    series: [
      {
        lineStyle: {
          width: 4,
          shadowColor: '#b4dcff',
          shadowBlur: 10,
          shadowOffsetY: 8
        },
        color: ['#139CFF'],
        data: data,
        type: 'line',
        smooth: true
      }
    ]
  })
  return chart
}
function barChart() {
  const chart = echarts.init(
    document.querySelector('#dean-chart2') as HTMLElement
  )
  chart.setOption({
    legend: {},
    tooltip: {},
    dataset: {
      dimensions: ['product', '2015', '2016', '2017'],
      source: [
        { product: '1月', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
        { product: '2月', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
        { product: '3月', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
        { product: '4月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '5月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '6月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '7月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '8月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '9月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '10月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '11月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '12月', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
      ]
    },
    xAxis: { type: 'category' },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
  })
}
function pieChart(data) {
  const chart = echarts.init(
    document.querySelector('#dean-chart3') as HTMLElement
  )
  chart.setOption({
    grid: {
      left: 0,
      right: 0,
      top: 10,
      bottom: 0
    },
    tooltip: {
      trigger: 'item'
    },
    // legend: {
    //   orient: 'vertical',
    //   right: 10,
    //   bottom: 20
    // },
    title: {
      text: `${ data[0] + data[1] + data[2] + data[3] }`,
      textStyle: {
        fontSize: 23,
        color: '#3AADFF'
      },
      subtext: '累计',
      subtextStyle: {
        fontSize: 18
      },
      left: 'center',
      top: 'center'
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['60%', '80%'],
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 8
        },
        label: {
          show: true,
          fontSize: 16
          // position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '12',
            fontWeight: 'bold'
          }
        },

        labelLine: {
          show: true
        },
        color: ['#4AAE13', '#2B9BF5', '#FF5656', '#4DCDB6'],
        data: [
          { value: data[0], name: '专利申请前' },
          { value: data[1], name: '成果赋权' },
          { value: data[2], name: '成果转化' },
          { value: data[3], name: '专利水平' }
        ]
      }
    ]
  })
  return chart
}
