
import { defineComponent, onMounted, ref, reactive, onBeforeUnmount } from 'vue';
import store from '@/store';
import * as echarts from 'echarts';
import Api from '@/api';
import Utils from '@/utils';
type chartType = {
  category: echarts.ECharts,
  pie:echarts.ECharts,
  bar:echarts.ECharts
}
export default defineComponent({
  setup() {
    onMounted(() => {
      get_http_loginInfo()
      get_http_evaluate()
      get_http_taskDetails()
      get_http_scoreToptenReviews()
      get_http_applyHistogramExpert('init')
    })
    onBeforeUnmount(() => {
      chartInstance.category.dispose()
    })
    const chartInstance:chartType = {} as chartType
    // ------------------------------
    const card1Data = reactive({
      name: store.state.userInfo.name,
      sexStr: store.state.userInfo.sex === 1 ? '先生' : '女士',
      loginTime: '07/10 15:00',
      registerDay: 200,
      loginDay: 53,
      places: 0 // 位数
    })
    function get_http_loginInfo() {
      Api.http_loginInfo({ role: 3 }).then(res => {
        if (res.code === '200') {
          const loginDate = new Date(res.data.login_time)
          card1Data.loginTime = `${ loginDate.getMonth() + 1 }/${ loginDate.getDate() } ${ loginDate.getHours().toString().padStart(2, '0') }:${ loginDate.getMinutes().toString().padStart(2, '0') }`
          card1Data.registerDay = res.data.register_length
          card1Data.loginDay = res.data.login_length
          card1Data.places = res.data.places
        }
      })
    }
    const card2Data = reactive({
      'score': 0,
      'qualityScoreRatio': 0,
      'attitudeScore': 0,
      'attitudeScoreRatio': 0,
      'timelinessScore': 0,
      'qualityScore': 0,
      'timelinessScoreRatio': 0
    })
    function get_http_evaluate() {
      Api.http_evaluate({}).then(res => {
        if (res.code === '200') {
          card2Data.score = res.data.score
          card2Data.qualityScoreRatio = Math.round(res.data.qualityScoreRatio * 100)
          card2Data.attitudeScore = res.data.attitudeScore
          card2Data.attitudeScoreRatio = Math.round(res.data.attitudeScoreRatio * 100)
          card2Data.timelinessScore = res.data.timelinessScore
          card2Data.qualityScore = res.data.qualityScore
          card2Data.timelinessScoreRatio = Math.round(res.data.timelinessScoreRatio * 100)
        }
      })
    }
    const card3Data = reactive({
      dateType: 1,
      onDateTypeChange(e) { get_http_taskDetails() },
      type: 0,
      onTypeChange(e) { get_http_taskDetails() },
      'monthEvaluate': 0, // 本月评价数
      'finishEvaluate': 0, // 完成评价数
      'avgScore': 0, // 平均分
      'toEvaluate': 0, // 待评价数
      'adoptionRates': 0, // 采纳率
      'countSum': 0// 总任务数
    })
    function get_http_taskDetails() {
      Api.http_taskDetails({ type: card3Data.type, section: card3Data.dateType }).then(res => {
        card3Data.monthEvaluate = res.data.monthEvaluate
        card3Data.finishEvaluate = res.data.finishEvaluate
        card3Data.avgScore = Math.round(res.data.avgScore)
        card3Data.toEvaluate = res.data.toEvaluate
        card3Data.adoptionRates = Math.round(res.data.adoptionRates * 100)
        card3Data.countSum = res.data.countSum
      })
    }
    const card4Data = reactive({
      chart: {
        xAxis: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        data: [820, 932, 901, 934, 190, 1330, 1320, 820, 932, 901, 934, 1290, 1330, 1320]
      },
      dateType: 1,
      onDateTypeChange(e) {
        if (e === 1) {
          card4Data.chart.xAxis = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
          // card5Data.chart.data = [820, 932, 901, 934, 190, 1330, 1320, 820, 932, 901, 934, 1290, 1330, 1320]
        }
        if (e === 2) {
          card4Data.chart.xAxis = ['1季度', '2季度', '3季度', '4季度']
          // card5Data.chart.data = [820, 932, 901, 934]
        }
        get_http_applyHistogramExpert()
      },
      type: 0,
      onTypeChange(e) { get_http_applyHistogramExpert() },
      year: new Date(),
      onYearChange() {
        get_http_applyHistogramExpert()
      }
    })
    function updatedCategory(chart) {
      chartInstance.category.setOption({
        xAxis: [
          {
            data: chart.xAxis
          }
        ],
        series: [
          {
            data: chart.data
          }
        ]
      });
      // chartInstance.category.resize()
    }
    function get_http_applyHistogramExpert(optType = 'update') {
      const postData = {
        type: card4Data.type,
        section: card4Data.dateType,
        year: card4Data.year.getFullYear()
      }
      Api.http_applyHistogramExpert(postData).then(res => {
        if (optType === 'init') {
          chartInstance.category = categoryChart(res.data)
        } else if (optType === 'update') {
          card4Data.chart.data = res.data
          updatedCategory(card4Data.chart)
        }
      })
    }

    const card5Data = reactive({
      calcColor(index:number) {
        if (index === 1) return '#FFC12E';
        if (index === 2) return '#6D98D2';
        if (index === 3) return '#FF9147';
        return '#7B7B7B'
      },
      calcColor2(index:number) {
        if (index === 1) return '#FFC12E';
        if (index === 2) return '#6D98D2';
        if (index === 3) return '#FF9147';
        return '#D7E7F8'
      },
      type: 0,
      onTypeChange(e) { get_http_scoreToptenReviews() },
      dateArr: [new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 180), new Date()],
      onDateArrChange(e) { get_http_scoreToptenReviews() },
      topTenList: [] as {name:string, score:number}[]
    })
    function get_http_scoreToptenReviews() {
      Api.http_scoreToptenReviews({ role: 3, offset: 0, length: 10, type: card5Data.type,
        start_time: Utils.dataFormat(card5Data.dateArr[0]),
        end_time: Utils.dataFormat(card5Data.dateArr[1]) }).then(res => {
        card5Data.topTenList = res.data.data
      })
    }

    return { card1Data, card2Data, card3Data, card4Data, card5Data }
  }
})

function categoryChart(data) {
  const chart = echarts.init(document.querySelector('#expert-chart1') as HTMLElement)
  chart.setOption({
    grid: {
      top: 20,
      right: 20,
      bottom: 35
    },
    xAxis: {
      type: 'category',
      data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      trigger: 'axis'
    },
    series: [
      {
        lineStyle: { width: 4,
          shadowColor: '#b4dcff',
          shadowBlur: 10,
          shadowOffsetY: 8 },
        color: ['#139CFF'],
        data: data,
        type: 'line',
        smooth: true
      }

    ]
  });
  return chart
}
