import { defineComponent, computed, ref } from 'vue';
import store from '@/store';
import * as echarts from 'echarts';
import { ElLoading } from 'element-plus'
import admin from './component/admin.vue'
import group from './component/group.vue'
import expert from './component/expert.vue'
import school from './component/school.vue'
import others from './component/others.vue'
import proposer from './component/proposer.vue'
export default defineComponent({
  components: { admin, group, expert, school, others, proposer },

  setup() {
    const roleKey = ref(0)
    const curRoleComp = computed(() => {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      roleKey.value++
      switch (Number(store.state.curRoleId)) {
        case 1:
          return 'admin';
        case 2:
          return 'proposer';
        case 3:
          return 'expert';
        default:
          return 'others';
      }
    })
    return { curRoleComp, roleKey }
  }
})
