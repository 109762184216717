
import { defineComponent, onMounted, ref, reactive, onBeforeUnmount } from 'vue'
import store from '@/store'
import router from '@/router'
import * as echarts from 'echarts'
import Api from '@/api'
type chartType = {
  category: echarts.ECharts
  pie: echarts.ECharts
  bar: echarts.ECharts
}

export default defineComponent({
  setup() {
    const card1Data = reactive({
      name: store.state.userInfo.name,
      sexStr: store.state.userInfo.sex === 1 ? '先生' : '女士',
      loginTime: '07/10 15:00',
      registerDay: 200,
      loginDay: 53
    })
    function get_http_loginInfo() {
      Api.http_loginInfo({ role: 1 }).then((res) => {
        if (res.code === '200') {
          const loginDate = new Date(res.data.login_time)
          card1Data.loginTime = `${
            loginDate.getMonth() + 1
          }/${ loginDate.getDate() } ${ loginDate
            .getHours()
            .toString()
            .padStart(2, '0') }:${ loginDate
            .getMinutes()
            .toString()
            .padStart(2, '0') }`
          card1Data.registerDay = res.data.register_length
          card1Data.loginDay = res.data.login_length
        }
      })
    }

    // -------------------
    const card2Data = reactive({
      select: 1,
      onSelectChange(e) {
        get_http_applyStatistics()
      },
      year: new Date(),
      onYearChange(e) {
        get_http_applyStatistics()
      },
      quarter: 0,
      onQuarterChange(e) {
        get_http_applyStatistics()
      },
      quarterCount: 0,
      quarterRatio: 0,
      yearRatio: 0
    })
    function get_http_applyStatistics() {
      Api.http_applyStatistics({
        year: card2Data.year.getFullYear(),
        quarter: card2Data.quarter,
        role: 1,
        type: card2Data.select
      }).then((res) => {
        if (res.code === '200') {
          card2Data.quarterCount = res.data.quarterCount
          card2Data.quarterRatio = Number(
            (res.data.quarterRatio * 100).toFixed(2)
          )
          card2Data.yearRatio = Number((res.data.yearRatio * 100).toFixed(2))
        }
      })
    }

    // ----------------------
    const card3Data = reactive({
      select: 1,
      onSelectChange(e) {
        get_http_applyPass()
      },
      year: new Date(),
      onYearChange(e) {
        get_http_applyPass()
      },
      quarter: 0,
      onQuarterChange(e) {
        get_http_applyPass()
      },
      passRatio: 0,
      submitCount: 0,
      passCount: 0
    })
    function get_http_applyPass() {
      Api.http_applyPass({
        year: card3Data.year.getFullYear(),
        quarter: card3Data.quarter,
        role: 1,
        type: card3Data.select
      }).then((res) => {
        if (res.code === '200') {
          card3Data.submitCount = res.data.quarterCount // 提交份数
          card3Data.passRatio = Number((res.data.quarterRatio * 100).toFixed(2)) // 页面显示%比
          card3Data.passCount = Number(res.data.quarterPassCount.toFixed(2)) // 通过份数
        }
      })
    }
    const card4Data = reactive({
      chart: {
        data: [
          // { value: 1048, name: '专利申请前' },
          { value: 1048, name: '成果赋权' },
          { value: 1048, name: '成果转化' }
        ],
        title: {
          text: ''
        }
      },
      year: new Date(),
      onYearChange() {
        get_http_sumUpWorkAdmin()
      }
      // disabledDate: (time:Date) => time.getTime() > Date.now()
    })
    function updatedPie() {
      chartInstance.pie.setOption({
        title: {
          text: card4Data.chart.title.text
        },
        series: {
          data: card4Data.chart.data
        }
      })
    }
    function get_http_sumUpWorkAdmin(optType = 'update') {
      Api.http_sumUpWorkAdmin({
        role: 1,
        year: card4Data.year.getFullYear()
      }).then((res) => {
        if (optType === 'init') {
          chartInstance.pie = pieChart([
            res.data.patentsCount,
            res.data.resEmpowersCount,
            res.data.resChangesCount,
            res.data.patentsSuffixCount
          ])
        } else if (optType === 'update') {
          card4Data.chart.data[0].value = res.data.patentsCount
          card4Data.chart.data[1].value = res.data.resEmpowersCount
          card4Data.chart.data[2].value = res.data.resChangesCount
          card4Data.chart.data[3].value = res.data.patentsSuffixCount
          card4Data.chart.title.text
            = res.data.patentsCount
            + res.data.resChangesCount
            + res.data.resEmpowersCount
            + res.data.patentsSuffixCount
          updatedPie()
        }
      })
    }

    const card5Data = reactive({
      chart: {
        xAxis: [
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月'
        ],
        data: [
          820,
          932,
          901,
          934,
          190,
          1330,
          1320,
          820,
          932,
          901,
          934,
          1290,
          1330,
          1320
        ]
      },
      select: 1,
      onSelectChange(e) {
        card5Data.radioBtnSelect = 0
        get_http_applyHistogram()
      },
      util: ['单位:件', '单位:件', '单位:件'],
      radioBtn: [
        ['全部', '转让许可', '作价投资'],
        ['全部', '所有权', '长期使用权'],
        ['提交数', '通过数']
      ],
      radioBtnSelect: 0,
      onRadioBtnSelectChange(e) {
        get_http_applyHistogram()
      },
      // curTab: 'first',
      // onTabToggleBefore(activeName, oldActiveName) { console.log(activeName, oldActiveName) },
      dateType: 1,
      onDateTypeChange(e) {
        if (e === 1) {
          card5Data.chart.xAxis = [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
          ]
          // card5Data.chart.data = [820, 932, 901, 934, 190, 1330, 1320, 820, 932, 901, 934, 1290, 1330, 1320]
        }
        if (e === 2) {
          card5Data.chart.xAxis = ['1季度', '2季度', '3季度', '4季度']
          // card5Data.chart.data = [820, 932, 901, 934]
        }
        get_http_applyHistogram()

        // updatedCategory(card5Data.chart)
      },
      year: new Date(),
      onYearChange(e) {
        get_http_applyHistogram()
      }
    })
    function updatedCategory(chart) {
      chartInstance.category.setOption({
        xAxis: [
          {
            data: chart.xAxis
          }
        ],
        series: [
          {
            data: chart.data
          }
        ]
      })
      // chartInstance.category.resize()
    }
    const access = store.state.access
    const first = access.findIndex((item) => item.name === 'pais')
    const second = access.findIndex((item) => item.name === 'proofreading')
    const third = access.findIndex((item) => item.name === 'assessor-audit')

    const card6Data = reactive({
      curTab: 'zero',
      opt: [
        { label: '全部代办事项', len: 0, name: 'zero', show: 0, list: [] },
        { label: '师生员工一审', len: 0, name: 'first', show: first, list: [] },
        {
          label: '师生员工二审',
          len: 0,
          name: 'second',
          show: second,
          list: []
        },
        { label: '评估员审核', len: 0, name: 'third', show: third, list: [] }
      ],
      onTabToggleBefore(activeName, oldActiveName) {
        console.log(activeName, oldActiveName)
      }
    })
    // 一审
    function get_http_firstInstance() {
      if (first < 0) return
      Api.http_firstInstance({ status: 0, type: 3, offset: 0, length: 3 }).then(
        (res) => {
          if (res.code === '200') {
            res.data.data.forEach((element, index) => {
              res.data.data[index].routerName = 'pais'
            })
            card6Data.opt[1].len = res.data.total
            card6Data.opt[1].list = res.data.data
            card6Data.opt[0].len += res.data.total
            card6Data.opt[0].list = card6Data.opt[0].list.concat(res.data.data)
          }
        }
      )
    }
    // 二审
    function get_http_secondInstance() {
      if (second < 0) return
      Api.http_secondInstance({
        status: 1,
        type: 3,
        offset: 0,
        length: 3
      }).then((res) => {
        if (res.code === '200') {
          res.data.data.forEach((element, index) => {
            res.data.data[index].routerName = 'proofreading'
          })
          card6Data.opt[2].len = res.data.total
          card6Data.opt[2].list = res.data.data
          card6Data.opt[0].len += res.data.total
          card6Data.opt[0].list = card6Data.opt[0].list.concat(res.data.data)
        }
      })
    }
    // 评估员审核
    function get_http_assessorAudit() {
      if (third < 0) return
      Api.http_assessorAudit({ status: 0, type: 2, offset: 0, length: 3 }).then(
        (res) => {
          if (res.code === '200') {
            res.data.data.forEach((element, index) => {
              res.data.data[index].routerName = 'assessor-audit'
            })
            card6Data.opt[3].len = res.data.total
            card6Data.opt[3].list = res.data.data
            card6Data.opt[0].len += res.data.total
            card6Data.opt[0].list = card6Data.opt[0].list.concat(res.data.data)
          }
        }
      )
    }
    function navToAudit(routerName) {
      router.push({ name: routerName })
    }

    const card7Data = reactive({
      chart: {
        xAxis: [
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月'
        ],
        name: [] as string[],
        data: [
          [
            820,
            932,
            901,
            934,
            190,
            1330,
            1320,
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ],
          [
            820,
            932,
            901,
            934,
            190,
            1330,
            1320,
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ],
          [
            820,
            932,
            901,
            934,
            190,
            1330,
            1320,
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ],
          [
            820,
            932,
            901,
            934,
            190,
            1330,
            1320,
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ],
          [
            820,
            932,
            901,
            934,
            190,
            1330,
            1320,
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ],
          [
            820,
            932,
            901,
            934,
            190,
            1330,
            1320,
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ],
          [
            820,
            932,
            901,
            934,
            190,
            1330,
            1320,
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ],
          [
            820,
            932,
            901,
            934,
            190,
            1330,
            1320,
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ],
          [
            820,
            932,
            901,
            934,
            190,
            1330,
            1320,
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ],
          [
            820,
            932,
            901,
            934,
            190,
            1330,
            1320,
            820,
            932,
            901,
            934,
            1290,
            1330,
            1320
          ]
        ]
      },
      select: 1,
      onSelectChange(e) {
        get_http_collegeTOP()
      },
      util: ['单位:件', '单位:件', '单位:件'],
      radioBtn: [
        ['学院', '申请人'],
        ['全部', '所有权', '长期使用权'],
        ['全部', '转让许可', '作价投资'],
        ['学院', '申请人']
      ],
      radioBtnSelect: 0,
      onRadioBtnSelectChange(e) {
        get_http_collegeTOP()
      },
      personType: 1,
      onPersonTypeChange(e) {
        get_http_collegeTOP()
      },
      dateType: 1,
      onDateTypeChange(e) {
        if (e === 1) {
          // const ele = document.querySelector('#chart3') as Element
          const ele = document.getElementById('chart3') as HTMLElement
          ele.style.width = '300%'

          card7Data.chart.xAxis = [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
          ]
          card7Data.chart.data.forEach((item, index) => {
            item.forEach((items, indexs) => {
              card7Data.chart.data[index][indexs] = items * Math.random()
            })
          })
        }
        if (e === 2) {
          const ele = document.getElementById('chart3') as HTMLElement
          ele.style.width = '100%'
          card7Data.chart.xAxis = ['1季度', '2季度', '3季度', '4季度']

          card7Data.chart.data.forEach((item, index) => {
            item.forEach((items, indexs) => {
              card7Data.chart.data[index][indexs] = items * Math.random()
            })
          })
        }
        get_http_collegeTOP()
      },
      year: new Date(),
      onYearChange(e) {
        get_http_collegeTOP()
      },
      type: 1,
      onTypeChange(e) {
        get_http_collegeTOP()
      }
    })
    function get_http_collegeTOP(optType = 'update') {
      Api.http_collegeTOP({
        apply_type:
          card7Data.select === 1 ? card7Data.type : card7Data.radioBtnSelect,
        type: card7Data.select,
        year: card7Data.year.getFullYear(),
        quarter: card7Data.dateType,
        college: card7Data.select === 1 ? card7Data.radioBtnSelect + 1 : 1
      }).then((res) => {
        card7Data.chart.data = []
        card7Data.chart.name = []
        const other = [] as any[] // 其他学院
        res.data.forEach((item: Array<any>, index) => {
          other.push(item.pop())
        })

        res.data.forEach((item, index) => {
          for (let i = 0; i < 10; i++) {
            if (index === 0) {
              card7Data.chart.data[i] = []
              card7Data.chart.name.push(
                item.length > i ? item[i].colleges : `暂无${ i + 1 }`
              )
            }
            card7Data.chart.data[i].push(item.length > i ? item[i].count : 0)
          }
          card7Data.chart.data[9] = other.map((items) => items.count)
          card7Data.chart.name[9] = other[index].colleges
        })
        if (optType === 'init') {
          chartInstance.bar = barChart(card7Data.chart)
        } else if (optType === 'update') {
          updatedBar(card7Data.chart)
        }
      })
    }
    function updatedBar(chart) {
      const series = chart.data.map((item, index) => {
        return {
          name: chart.name[index],
          data: item,
          type: 'bar',
          smooth: true,
          barMinHeight: 5
        }
      })
      chartInstance.bar.setOption({
        xAxis: [
          {
            data: null
          }
        ],
        series: [
          { name: '', data: 0 },
          { name: '', data: 0 },
          { name: '', data: 0 },
          { name: '', data: 0 },
          { name: '', data: 0 },
          { name: '', data: 0 },
          { name: '', data: 0 },
          { name: '', data: 0 },
          { name: '', data: 0 },
          { name: '', data: 0 }
        ]
      })
      chartInstance.bar.resize()
      chartInstance.bar.setOption({
        xAxis: [
          {
            data: chart.xAxis
          }
        ],
        series: series
      })

      chartInstance.bar.resize()
    }

    // 获取趋势图
    function get_http_applyHistogram(optType = 'update') {
      let way = card5Data.radioBtnSelect
      if (card5Data.select === 3) way++
      Api.http_applyHistogram({
        type: card5Data.select,
        way: way,
        section: card5Data.dateType,
        year: card5Data.year.getFullYear(),
        role: 1
      }).then((res) => {
        if (res.code === '200') {
          if (optType === 'init') {
            chartInstance.category = categoryChart(res.data)
          } else if (optType === 'update') {
            card5Data.chart.data = res.data
            updatedCategory(card5Data.chart)
          }
        }
      })
    }
    onMounted(() => {
      get_http_loginInfo()
      get_http_applyStatistics()
      get_http_firstInstance()
      get_http_secondInstance()
      get_http_assessorAudit()
      get_http_applyPass()
      get_http_applyHistogram('init')
      get_http_sumUpWorkAdmin('init')
      get_http_collegeTOP('init')
      // chartInstance.category = categoryChart()
      // chartInstance.bar = barChart()
    })
    const chartInstance: chartType = {} as chartType
    onBeforeUnmount(() => {
      chartInstance.bar.dispose()
      chartInstance.pie.dispose()
      chartInstance.category.dispose()
    })
    return {
      card1Data,
      card2Data,
      card3Data,
      card4Data,
      card5Data,
      card6Data,
      card7Data,
      navToAudit
    }
  }
})

function pieChart(data) {
  const chart = echarts.init(document.querySelector('#chart1') as HTMLElement)
  chart.setOption({
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    },
    tooltip: {
      trigger: 'item'
    },
    // legend: {
    //   orient: 'vertical',
    //   right: 10,
    //   bottom: 20
    // },
    title: {
      text: `${ data[0] + data[1] + data[2] + data[3] }`,
      textStyle: {
        fontSize: 23,
        color: '#3AADFF'
      },
      subtext: '累计',
      subtextStyle: {
        fontSize: 18
      },
      left: 'center',
      top: 'center'
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['60%', '80%'],
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 8
        },

        label: {
          show: true,
          fontSize: 16
          // position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '12',
            fontWeight: 'bold'
          }
        },

        labelLine: {
          show: true
        },
        color: ['#4AAE13', '#2B9BF5', '#FF5656', '#4DCDB6'],
        data: [
          { value: data[0], name: '专利申请前' },
          { value: data[1], name: '成果赋权' },
          { value: data[2], name: '成果转化' },
          { value: data[3], name: '专利水平' }
        ]
      }
    ]
  })
  return chart
}
function categoryChart(data): echarts.ECharts {
  const chart = echarts.init(document.querySelector('#chart2') as HTMLElement)
  chart.setOption({
    grid: {
      left: 60,
      right: 30,
      top: 10,
      bottom: 30
    },
    xAxis: {
      type: 'category',
      data: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月'
      ]
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      trigger: 'axis'
    },

    series: [
      {
        lineStyle: {
          width: 4,
          shadowColor: '#b4dcff',
          shadowBlur: 10,
          shadowOffsetY: 8
        },
        color: ['#139CFF'],
        data: data,
        type: 'line',
        smooth: true
      }
      // {
      //   data: [820, 932, 901, 934, 1290, 1330, 1320, 820, 932, 901, 934, 1290, 1330, 1320],
      //   type: 'line',
      //   smooth: true
      // },
      // {
      //   data: [820, 932, 901, 934, 1290, 1330, 130, 820, 932, 901, 934, 1290, 1330, 1320],
      //   type: 'line',
      //   smooth: true
      // }
    ]
  })
  return chart
}
function barChart(chartdata): echarts.ECharts {
  const series = chartdata.data.map((item, index) => {
    return {
      name: chartdata.name[index],
      data: item,
      type: 'bar',
      smooth: true,
      barMinHeight: 5
    }
  })
  const chart = echarts.init(document.querySelector('#chart3') as HTMLElement)
  chart.setOption({
    grid: {
      left: 60,
      right: 40,
      top: 10,
      bottom: 30
    },
    xAxis: {
      type: 'category',
      data: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月'
      ],
      axisLabel: { fontSize: 20 }
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow'
      },
      formatter: '{a}:{c}'
    },
    color: [
      new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: '#FF7878' },
        { offset: 1, color: '#FF4E4E' }
      ]),
      new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: '#7EC7FF' },
        { offset: 1, color: '#32A1F7' }
      ]),
      new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: '#C88BFF' },
        { offset: 1, color: '#B159FF' }
      ]),
      new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: '#5EE5A2' },
        { offset: 1, color: '#48CD8B' }
      ]),
      new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: '#FEC600' },
        { offset: 1, color: '#FF9119' }
      ]),
      new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: '#9AB1FF' },
        { offset: 1, color: '#6887F1' }
      ]),
      new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: '#A8AFB7' },
        { offset: 1, color: '#87919B' }
      ]),
      new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: '#A6EB6D' },
        { offset: 1, color: '#50BC1B' }
      ]),
      new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: '#707B88' },
        { offset: 1, color: '#374859' }
      ]),
      new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: '#FFE35E' },
        { offset: 1, color: '#FFD929' }
      ])
    ],
    series: series
  })
  return chart
}
