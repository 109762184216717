
import { defineComponent, onMounted, ref, reactive } from 'vue';
import * as echarts from 'echarts';
export default defineComponent({
  setup() {
    onMounted(() => {
      categoryChart()
      barChart()
    })
  }
})

function categoryChart() {
  const chart = echarts.init(document.querySelector('#school-chart1') as HTMLElement)
  chart.setOption({
    xAxis: {
      type: 'category',
      data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [820, 932, 901, 934, 190, 1330, 1320, 820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true
      }

    ]
  });
}
function barChart() {
  const chart = echarts.init(document.querySelector('#school-chart2') as HTMLElement)
  chart.setOption({
    legend: {},
    tooltip: {},
    dataset: {
      dimensions: ['product', '2015', '2016', '2017'],
      source: [
        { product: '1月', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
        { product: '2月', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
        { product: '3月', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
        { product: '4月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '5月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '6月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '7月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '8月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '9月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '10月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '11月', 2015: 72.4, 2016: 53.9, 2017: 39.1 },
        { product: '12月', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
      ]
    },
    xAxis: { type: 'category' },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
  });
}

