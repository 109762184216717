
import { defineComponent, onMounted, ref, reactive, onBeforeUnmount } from 'vue'
import store from '@/store'
import * as echarts from 'echarts'
import Api from '@/api'
import Utils from '../../../../utils/index'
type chartType = {
  category: echarts.ECharts
  pie: echarts.ECharts
  bar: echarts.ECharts
}
export default defineComponent({
  setup() {
    const chartInstance: chartType = {} as chartType
    // --------------------
    const card1Data = reactive({
      name: store.state.userInfo.name,
      sexStr: store.state.userInfo.sex === 1 ? '先生' : '女士',
      loginTime: '07/10 15:00',
      registerDay: 200,
      loginDay: 53,
      places: 0 // 位数
    })
    function get_http_loginInfo() {
      Api.http_loginInfo({ role: 2 }).then((res) => {
        if (res.code === '200') {
          const loginDate = new Date(res.data.login_time)
          card1Data.loginTime = `${
            loginDate.getMonth() + 1
          }/${ loginDate.getDate() } ${ loginDate
            .getHours()
            .toString()
            .padStart(2, '0') }:${ loginDate
            .getMinutes()
            .toString()
            .padStart(2, '0') }`
          card1Data.registerDay = res.data.register_length
          card1Data.loginDay = res.data.login_length
          card1Data.places = res.data.places
        }
      })
    }
    // -------------------------
    const card2Data = reactive({
      select: 1,
      onSelectChange(e) {
        get_http_applyStatistics()
      },
      year: new Date(),
      onYearChange(e) {
        get_http_applyStatistics()
      },
      quarter: 0, // 季度
      onQuarterChange(e) {
        get_http_applyStatistics()
      },
      quarterCount: 0,
      quarterRatio: 0,
      yearRatio: 0
    })
    function get_http_applyStatistics() {
      Api.http_applyStatistics({
        year: card2Data.year.getFullYear(),
        quarter: card2Data.quarter,
        role: 2,
        type: card2Data.select
      }).then((res) => {
        if (res.code === '200') {
          card2Data.quarterCount = res.data.quarterCount
          card2Data.quarterRatio = Number(
            (res.data.quarterRatio * 100).toFixed(2)
          )
          card2Data.yearRatio = Number((res.data.yearRatio * 100).toFixed(2))
        }
      })
    }
    // -------------------------
    const card3Data = reactive({
      select: 1,
      onSelectChange(e) {
        get_http_applyPass()
      },
      year: new Date(),
      onYearChange(e) {
        get_http_applyPass()
      },
      quarter: 0, // 季度
      onQuarterChange(e) {
        get_http_applyPass()
      },
      passRatio: 0,
      submitCount: 0,
      passCount: 0
    })
    function get_http_applyPass() {
      Api.http_applyPass({
        year: card3Data.year.getFullYear(),
        quarter: card3Data.quarter,
        role: 2,
        type: card3Data.select
      }).then((res) => {
        if (res.code === '200') {
          card3Data.submitCount = res.data.quarterCount // 提交份数
          card3Data.passRatio = Number((res.data.quarterRatio * 100).toFixed(2)) // 页面显示%比
          card3Data.passCount = res.data.quarterPassCount // 通过份数
        }
      })
    }
    // -------------------------
    const card4Data = reactive({
      year: new Date(),
      onYearChange(e) {
        get_http_patentsArgScore()
      },
      quarter: 0, // 季度
      onQuarterChange(e) {
        get_http_patentsArgScore()
      },
      type: 1,
      onTypeChange(e) {
        get_http_patentsArgScore()
      },
      score: 0
    })
    function get_http_patentsArgScore() {
      Api.http_patentsArgScore({
        type: card4Data.type,
        year: card4Data.year.getFullYear(),
        quarter: card4Data.quarter
      }).then((res) => {
        card4Data.score = res.data.score
      })
    }

    // ------------------------
    const card5Data = reactive({
      chart: {
        xAxis: [
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月'
        ],
        data: [
          820,
          932,
          901,
          934,
          190,
          1330,
          1320,
          820,
          932,
          901,
          934,
          1290,
          1330,
          1320
        ]
      },
      select: 1,
      onSelectChange(e) {
        card5Data.radioBtnSelect = 0
        get_http_applyHistogram()
      },
      util: ['单位:件', '单位:件', '单位:件'],
      radioBtn: [
        ['全部', '转让许可', '作价投资'],
        ['全部', '所有权', '长期使用权'],
        ['提交数', '通过数']
      ],
      radioBtnSelect: 0,
      onRadioBtnSelectChange(e) {
        get_http_applyHistogram()
      },
      // curTab: 'first',
      // onTabToggleBefore(activeName, oldActiveName) { console.log(activeName, oldActiveName) },
      dateType: 1,
      onDateTypeChange(e) {
        if (e === 1) {
          card5Data.chart.xAxis = [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
          ]
          // card5Data.chart.data = [820, 932, 901, 934, 190, 1330, 1320, 820, 932, 901, 934, 1290, 1330, 1320]
        }
        if (e === 2) {
          card5Data.chart.xAxis = ['1季度', '2季度', '3季度', '4季度']
          // card5Data.chart.data = [820, 932, 901, 934]
        }
        get_http_applyHistogram()
        // updatedCategory(card5Data.chart)
      },
      year: new Date(),
      onYearChange(e) {
        get_http_applyHistogram()
      }
    })
    function updatedCategory(chart) {
      console.log('updatedCategory', chart.data)
      chartInstance.category.setOption({
        xAxis: [
          {
            data: chart.xAxis
          }
        ],
        series: [
          {
            data: chart.data
          }
        ]
      })
    }
    // 获取趋势图
    function get_http_applyHistogram(optType = 'update') {
      let way = card5Data.radioBtnSelect
      if (card5Data.select === 3) way++
      Api.http_applyHistogram({
        type: card5Data.select,
        way: way,
        section: card5Data.dateType,
        year: card5Data.year.getFullYear(),
        role: 2
      }).then((res) => {
        if (res.code === '200') {
          if (optType === 'init') {
            chartInstance.category = categoryChart(res.data)
          } else if (optType === 'update') {
            card5Data.chart.data = res.data
            updatedCategory(card5Data.chart)
          }
        }
      })
    }
    // ------------------------
    const card6Data = reactive({
      chart: {
        data: [
          { value: 1048, name: '作价投资' },
          { value: 735, name: '成果转让' }
        ],
        title: {
          text: ''
        }
      },
      select: 1,
      onSelectChange(e) {
        switch (e) {
          case 1:
            card6Data.setChartDataName(['转让许可', '作价投资'])
            break
          case 2:
            card6Data.setChartDataName(['所有权', '长期使用权'])
            break
          case 3:
            card6Data.setChartDataName(['未评价', '已评价'])
            break
        }
        get_http_sumUpWork()
      },
      setChartDataName(arr) {
        card6Data.chart.data[0].name = arr[0]
        card6Data.chart.data[1].name = arr[1]
      },
      dateArr: [
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 180),
        new Date()
      ],
      onDateArrChange(e) {
        get_http_sumUpWork()
      },
      apply: 0,
      pass: 0,
      ontPass: 0,
      evaluates: 0
    })
    function updatedPie() {
      chartInstance.pie.setOption({
        title: {
          text: card6Data.chart.title.text
        },
        series: {
          data: card6Data.chart.data
        }
      })
    }
    function get_http_sumUpWork(optType = 'update') {
      Api.http_sumUpWork({
        type: card6Data.select,
        start_time: Utils.dataFormat(card6Data.dateArr[0]),
        end_time: Utils.dataFormat(card6Data.dateArr[1])
      }).then((res) => {
        if (optType === 'init') {
          chartInstance.pie = pieChart([res.data.workSum1, res.data.workSum2])
        } else if (optType === 'update') {
          card6Data.chart.data[0].value = res.data.workSum1
          card6Data.chart.data[1].value = res.data.workSum2
          let util = ''
          if (card6Data.select === 1) util = ''
          card6Data.chart.title.text = res.data.total + util
          updatedPie()
        }
        card6Data.apply = res.data.apply
        card6Data.pass = res.data.pass
        card6Data.ontPass = res.data.ontPass
        card6Data.evaluates = res.data.evaluates
      })
    }
    const card7Data = reactive({
      calcColor(index: number) {
        if (index === 1) return '#FFC12E'
        if (index === 2) return '#6D98D2'
        if (index === 3) return '#FF9147'
        return '#7B7B7B'
      },
      calcColor2(index: number) {
        if (index === 1) return '#FFC12E'
        if (index === 2) return '#6D98D2'
        if (index === 3) return '#FF9147'
        return '#D7E7F8'
      },
      topTenList: [] as { name: string; score: number }[]
    })
    function get_http_scoreToptenReviews() {
      Api.http_scoreToptenReviews({ role: 2, offset: 0, length: 10 }).then(
        (res) => {
          card7Data.topTenList = res.data.data
        }
      )
    }
    onMounted(() => {
      get_http_loginInfo()
      get_http_applyStatistics()
      get_http_applyPass()
      get_http_sumUpWork('init')
      get_http_applyHistogram('init')
      get_http_scoreToptenReviews()
      // chartInstance.category = categoryChart()
      get_http_patentsArgScore()
    })
    onBeforeUnmount(() => {
      chartInstance.category.dispose()
      chartInstance.pie.dispose()
    })
    return {
      card1Data,
      card2Data,
      card3Data,
      card4Data,
      card5Data,
      card6Data,
      card7Data
    }
  }
})

function categoryChart(data): echarts.ECharts {
  const chart = echarts.init(
    document.querySelector('#proposer-chart1') as HTMLElement
  )
  chart.setOption({
    grid: {
      left: 60,
      right: 30,
      top: 10,
      bottom: 50
    },
    xAxis: {
      type: 'category',
      data: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月'
      ],
      axisLabel: {
        fontSize: 16,
        padding: [15, 0, 0, 0]
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    yAxis: {
      type: 'value',
      splitNumber: 4,
      axisLabel: {
        fontSize: 16
      }
    },
    series: [
      {
        lineStyle: {
          width: 4,
          shadowColor: '#b4dcff',
          shadowBlur: 10,
          shadowOffsetY: 8
        },
        color: ['#139CFF'],
        // barMaxWidth: 25,
        data: data,
        type: 'line',
        // color: [new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: '#7EC7FF' }, { offset: 1, color: '#32A1F7' }])],
        smooth: true
      }
    ]
  })
  return chart
}

function pieChart(data): echarts.ECharts {
  const chart = echarts.init(
    document.querySelector('#proposer-chart3') as HTMLElement
  )
  chart.setOption({
    grid: {
      left: 0,
      right: 0,
      top: 10,
      bottom: 0
    },
    tooltip: {
      trigger: 'item'
    },
    // legend: {
    //   orient: 'vertical',
    //   right: 10,
    //   bottom: 20
    // },
    title: {
      text: `${ data[0] + data[1] }`,
      textStyle: {
        fontSize: 32,
        color: '#3AADFF'
      },
      subtext: '累计',
      subtextStyle: {
        fontSize: 20
      },
      left: 'center',
      top: 'center'
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['48%', '60%'],
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 6
        },
        label: {
          show: true,
          fontSize: 16
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '12',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: true
        },
        color: ['#2B9BF5', '#4AAE13'],
        data: [
          { value: data[0], name: '作价投资' },
          { value: data[1], name: '成果转让' }
        ]
      }
    ]
  })
  return chart
}
