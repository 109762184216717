import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-e96734f4")
const _hoisted_1 = { class: "workbench" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_proposer = _resolveComponent("proposer")!
  const _component_expert = _resolveComponent("expert")!
  const _component_admin = _resolveComponent("admin")!
  const _component_group = _resolveComponent("group")!
  const _component_others = _resolveComponent("others")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.curRoleComp == 'proposer')
      ? (_openBlock(), _createBlock(_component_proposer, {
          key: 0,
          style: {"width":"100%","height":"100%"}
        }))
      : _createCommentVNode("", true),
    (_ctx.curRoleComp == 'expert')
      ? (_openBlock(), _createBlock(_component_expert, {
          key: 1,
          style: {"width":"100%","height":"100%"}
        }))
      : _createCommentVNode("", true),
    (_ctx.curRoleComp == 'admin')
      ? (_openBlock(), _createBlock(_component_admin, {
          key: 2,
          style: {"width":"100%","height":"100%"}
        }))
      : _createCommentVNode("", true),
    (_ctx.curRoleComp == 'group')
      ? (_openBlock(), _createBlock(_component_group, {
          key: 3,
          style: {"width":"100%","height":"100%"}
        }))
      : _createCommentVNode("", true),
    (_ctx.curRoleComp == 'others')
      ? (_openBlock(), _createBlock(_component_others, {
          key: 4,
          style: {"width":"100%","height":"100%"}
        }))
      : _createCommentVNode("", true)
  ]))
}