
import { defineComponent, onMounted, ref, reactive } from 'vue';
import * as echarts from 'echarts';
export default defineComponent({
  setup() {
    onMounted(() => {
      categoryChart()
      pieChart2()
      pieChart3()
    })
  }
})

function categoryChart() {
  const chart = echarts.init(document.querySelector('#group-chart1') as HTMLElement)
  chart.setOption({
    xAxis: {
      type: 'category',
      data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [820, 932, 901, 934, 190, 1330, 1320, 820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true
      },
      {
        data: [820, 932, 901, 934, 1290, 1330, 1320, 820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true
      },
      {
        data: [820, 932, 901, 934, 1290, 1330, 130, 820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true
      }
    ]
  });
}

function pieChart2() {
  const chart = echarts.init(document.querySelector('#group-chart2') as HTMLElement)
  chart.setOption({
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      right: 10,
      bottom: 20
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['55%', '70%'],
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 1
        },
        label: {
          show: true
          // position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '12',
            fontWeight: 'bold'
          }
        },

        labelLine: {
          show: true
        },
        data: [
          { value: 1048, name: '待评价' },
          { value: 1048, name: '待评价1' },
          { value: 1048, name: '待评价2' },
          { value: 735, name: '已评价' }
        ]
      }
    ]
  });
}
function pieChart3() {
  const chart = echarts.init(document.querySelector('#group-chart3') as HTMLElement)
  chart.setOption({
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      right: 10,
      bottom: 20
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['55%', '70%'],
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 1
        },
        label: {
          show: true
          // position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '12',
            fontWeight: 'bold'
          }
        },

        labelLine: {
          show: true
        },
        data: [
          { value: 1048, name: '待评价' },
          { value: 1048, name: '待评价1' },
          { value: 1048, name: '待评价2' },
          { value: 735, name: '已评价' }
        ]
      }
    ]
  });
}
